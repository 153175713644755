/**
  Company : © IZEI Consulting Group.
  Description : Dedicated website, for healthcloud campaign.
  Date :  July 16th, 2021
  Author : Melisa Chavez, Isaí Záyago.
  |SV09|
 */

import React from 'react';
import '../../App.css';
import Cards from '../healthcloud';
import HeroSection from '../HeroSection';
import SectionAbout from '../SectionAbout';
import SectionContact from '../SectionContact';



function Home() {
  return (
    <>
    

      <HeroSection />
      <Cards/>
      <SectionContact/>
      <SectionAbout/>
      
     

    
  
    </>
  );
}

export default Home;