/**
  Company : © IZEI Consulting Group.
  Description : Dedicated website, for healthcloud campaign.
  Date :  July 16th, 2021
  Author : Melisa Chavez, Isaí Záyago.
 */

import React from 'react'
import "./SectionContact.css";
import {Button} from 'react-bootstrap';

function SectionContact() {
    return (
        <>
            <div className="container_contact">
				<div className="img_brackets">
						<div className="textos_section">
							
							<h1 className="about-titles">Comienza a implementar estrategias centradas en el paciente.</h1>
							
							<p className="about-body">Integra flujos de trabajo en una plataforma segura y
									manten conectados a equipos de atención al cliente, 
									investigadores, doctores y pacientes en un mismo lugar.</p>

							<Button href="https://www.izeicg.com/contacto" className="btn_contact" variant="light">Contáctanos</Button>	
							
						</div>
				</div>
	                                                                
	        </div>	
            
        </>
    )
}

export default SectionContact
