/**
  Company : © IZEI Consulting Group.
  Description : Dedicated website, for healthcloud campaign.
  Date :  July 16th, 2021
  Author : Melisa Chavez, Isaí Záyago.
 */

import React from "react";
import "./Cards.css";
import {Card, CardDeck, Image} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function Cards() {
  return (
    <div className="cards" id="Id_Cards">


    <div className = "grid-logo">
        <div className="card-logo-grid">
            <img className="non-responsive-logo" src="./img/health_cloud_izei_salesforce.png" alt="health cloud logo"  />  
            <img className="responsive-logo" src="./img/health_cloud_izei_salesforce_respo.png" alt="health cloud logo"  />  
        </div>
    </div>



     



     
      <h1 className="title-h1">Soluciones con Health Cloud</h1>
      <p className="sub-text">Health Cloud ayuda a los equipos de todas las disciplinas de la salud y las ciencias biológicas a impulsar la transformación digital en una plataforma segura y personalizable.</p>
    



    <div className="cards__container">

        
        <div className="cards__wrapper">
            
            <CardDeck>
                

                <Card className="card-styles">
                    <Card.Body>
                        <img className="padding-card-healt" src="./img/omnicanal_izei.png" alt="omnicanal izei"  />  
                    <Card.Text className="cards-bodys">
                        
                     Integración para habilitar una comunicación omnicanal.
                    </Card.Text>
                    </Card.Body>
                </Card>

                <Card className="card-styles">
                    <Card.Body>
                        <img className="padding-card-healt" src="./img/dashboard_izei_healthcloud.png" alt="patient management izei"  />
                    <Card.Text className="cards-bodys">
                       
                        Administración y gestión
                        360º del paciente en un 
                        mismo lugar.
                    </Card.Text>
                    </Card.Body>
                </Card>

                <Card className="card-styles">
                    <Card.Body>
                        <img className="img-card" src="./img/wearables_izei.png" alt="wearables izei"  />
                    <Card.Text className="cards-bodys">
                        
                        Adopción de nuevas 
                        tecnologías y administración del cambio.
                    </Card.Text>
                    </Card.Body>
                </Card>
            </CardDeck>
                   
           

            <div className="container-cards container-boot colPadding">
     
                <div className = "row-uno flex-center">
                    <Image className="data-cards" src="./img/health_cloud_aseguradoras.png" alt="health cloud aseguradoras" fluid />
                </div>


                <div>
                    <div className ="padding-text">
                            <p className="titulos"><span className="text-color">Aseguradoras:</span> Mantén la comunicación con los pacientes a lo largo de su recorrido.</p>
                            
                            <p className="subtitulo">Centralización del paciente</p>
    
                            <p className="cuerpo">
                                Envía información y recordatorios sobre atención preventiva y otros beneficios a pacientes de alto riesgo para una intervención más oportuna.
                            </p>
                            
                            <p className="subtitulo">Vista de 360</p>
    
                            <p className="cuerpo">
                                Los agentes acceden rápidamente al historial médico y los detalles del proveedor, la información del plan, el historial de casos…
                            </p>
                           
                           {/*
                            <p className="subtitulo">Comunicación a través de diferentes canales</p>
    
                            <p className="cuerpo">
                                Disponibilidad de variedad de canales, incluidos teléfono, chat en vivo, correo electrónico, aplicaciones móviles, mensajes de texto, web y aseguramiento de que funcionen bien juntos.
                            </p>
    
                            */ }
                    </div>
                           

                </div>
            </div>

            <div className="container-card container-boot colPadding">
                <div className = "row-uno">

                    <div className ="padding-text">

                        <p className="titulos"><span className="text-color"> Farmacéuticas:</span> Impulsa el compromiso con los pacientes en una vista completa de las acciones.</p>
                        
                            
                        <p className="subtitulo">Conecta el ciclo de vida del cliente</p>

                        <p className="cuerpo">
                            El acceso a información de proveedores, rendimiento de marketing y ventas con análisis integrados e inteligencia artificial impulsa una mayor lealtad.
                        </p>
                        <p className="subtitulo">Acelerar los ensayos clínicos</p>

                        <p className="cuerpo">
                            Recluta e inscribe pacientes fácilmente con herramientas que evalúan y cuantifican la capacidad del muestreo en la prueba.
                        </p>
                           
                           {/*}
                        <p className="subtitulo">Crea relaciones sólidas con los pacientes</p>

                        <p className="cuerpo">
                            Los agentes de servicio pueden conectarse directamente con los pacientes para coordinar la incorporación y brindar apoyo para mejorar el cumplimiento del programa, todo desde una sola vista.
                        </p>
                            */}
                    </div>
                       


                </div>
                <div className ="row-dos padding-col">
                    <Image className="data-cards" src="./img/health_cloud_farma.png" alt="health cloud farma" fluid />
                </div>
            </div> 




            <div className="container-cards container-boot col_padding">
     
                <div className = "row-uno flex-center">
                    <Image className="data-cards" src="./img/health_cloud_hospitales.png" alt="health cloud hospitales" fluid />
                </div>

                <div className =" padding-col">

                            <div className ="padding-text">

                                <p className="titulos"><span className="text-color"> Hospitales y Clínicas:</span> Evoluciona las expectativas de los pacientes.</p>
                                
                                <p className="subtitulo">Incrementa la eficiencia en la operación</p>
        
                                <p className="cuerpo">
                                    Mantén la operación y las persona enfocadas en tareas relevantes automatizando procesos por medio de una comunicación personalizada.
                                </p>
                                <p className="subtitulo">Comunicación en diferentes canales</p>
        
                                <p className="cuerpo">
                                    Disponibilidad de variedad de canales, incluidos teléfono, chat en vivo, correo electrónico, aplicaciones móviles, mensajes de texto y web.
                                </p>
                                {/*}
                                <p className="subtitulo">Segmentación y uso de datos</p>
        
                                <p className="cuerpo">
                                    La capacidad de identificar pacientes por alto riesgo en planes de control de bienestar o enfermedades.
                                </p>
                                */}
                            </div>
                            
    

                </div>
            </div>

           


          

         

        </div>
    </div>


   
    

 
    </div>



  );
}

export default Cards;


