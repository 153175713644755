import React from 'react';
import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/pages/Home';
import healthcloud from './components/healthcloud';
import Footer from './components/footer';
import Mensaje from './components/Mensaje';

function App() {
  return (
    <>
      <Router>
        <Navbar/>
        
        <Switch>
          <Route path='/' exact component={Home}/>
          <Route path='/healthcloud' exact component={healthcloud}/>
          <Route path='/Message' exact component={Mensaje}/>
        </Switch>
        <Footer/>
      </Router>
    </>
  );
}

export default App;
