/**
  Company : © IZEI Consulting Group.
  Description : Dedicated website, for healthcloud campaign.
  Date :  July 16th, 2021
  Author : Melisa Chavez, Isaí Záyago.
 */

import React from 'react'
import "./SectionAbout.css";
import {Card, CardDeck, Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';
function SectionAbout() {
    return (
        <>

            <div class="container-custom">

                <div class="row">

                    <div class="col-sm-4 col-about">
                    
                        <img className="logo-style-sc" src="./img/izei_logo.png" alt="izei logo"  />
                    </div>





                    <div class="col-sm-8 colDos">

                        <h5 className="about-h5">Transformación digital</h5>
                        <h1 className="about-h1">Izei Consulting Group</h1>
                        <div className="div-about">
                        Nos respaldan más de 20 años de experiencia en estrategia y soporte para la implementación de mejores prácticas con enfoque de crecimiento y rentabilidad. Tecnología enfocada en diseño e implementación de estrategias digitales y administración del cambio.
                        </div>

                        <CardDeck>
                            <Card className="custom-cards">
                                
                                <Card.Body className="card-body">
                                <Card.Title className="custom-cards-title">+10
                                
                                </Card.Title>
                                <Card.Text className="card-text-section">
                                    Operación en países de Latinoamérica
                                </Card.Text>
                                    <div className="btn-2em">
                                        <Button className="btn-section-about" variant="primary">Conoce Más</Button>
                                    </div>
                                    
                                </Card.Body>
                            
                            

                            </Card>
                            <Card className="custom-cards">
                                
                                <Card.Body >
                                <Card.Title className="custom-cards-title">+15 años</Card.Title>
                                <Card.Text className="card-text-section">
                                    Experiencia en diferentes industrias
                                </Card.Text>
                                </Card.Body>
                                
                                
                            </Card>
                            <Card className="custom-cards">
                            
                                <Card.Body>
                                <Card.Title className="custom-cards-title">+100</Card.Title>
                                <Card.Text className="card-text-section">
                                    Proyectos de <br></br>transformación
                                </Card.Text>
                                </Card.Body>
                                
                            </Card>
                        </CardDeck>


                        <div className="btn-bottom">
                        <Link to={{ pathname: "https://www.izeicg.com/" }} target="_blank">
                            <Button className="btn-section-about" variant="primary">Conoce Más</Button>
                        </Link>
                            
                        </div>
                        

                    </div>
                    
                
                </div>
                
        

            </div>
        </>
        
    )
}

export default SectionAbout


