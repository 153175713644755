/**
  Company : © IZEI Consulting Group.
  Description : Dedicated website, for healthcloud campaign.
  Date :  July 16th, 2021
  Author : Melisa Chavez, Isaí Záyago.
 */

import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css';

function Navbar() {
    
    const [click, setClick] = useState(false);
    const [setButton] = useState(true);
    const handleClick = () => setClick(!click);
    const navTopPage = () => (window.scrollTo(0, 0));
    const closeMobileMenu = () => setClick(false, window.scrollTo(0, 0));
   
    const showButton = () => {
        if(window.innerWidth <=960){
            setButton(false);
        }else{
            setButton(true);
        }
    };

    window.addEventListener('resize', showButton);

 
   
    return (
        <>
        
            <nav className="navbar">
                
                <div className="navbar-container">
                    <div className="navbar-imglogo">
                        <img onClick={navTopPage} className="img-fluid" src="./img/logo_izei.png" alt="logo izei" />
                     
                    </div>   
                </div>


                <div className="navbar-menu">

                    <div className='menu-icon' onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'}></i>
                    </div>
                    
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>

                        <li className='nav-item'>
                            <Link to="/" className='nav-links' onClick={closeMobileMenu}>
                                INICIO
                            </Link>
                        </li>
                  
                        <li className='nav-item'>
                            <Link to="/healthcloud" className='nav-links' onClick={closeMobileMenu}>
                                HEALTH CLOUD
                            </Link>
                        </li>

                        <li className='nav-item'>
                            <Link  to={{ pathname: "https://www.izeicg.com/contacto" }} target="_blank" className='nav-links' onClick={closeMobileMenu}>
                                CONTACTO
                            </Link>
                        </li>
                    </ul>

                </div>
            </nav>
        </>  
    )
}

export default Navbar

