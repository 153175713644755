/**
  Company : © IZEI Consulting Group.
  Description : Dedicated website, for healthcloud campaign.
  Date :  July 16th, 2021
  Author : Melisa Chavez, Isaí Záyago.
 */

  
import '../App.css';
import './HeroSection.css';
import emailjs from 'emailjs-com';
import {Form, Button, Col,Row} from 'react-bootstrap';




function HeroSection() {

  

    const data = {
        name : '',
        email : '',
        company : '',
        phone : ''
    };

   
    const validateEmail = (e) => {
        var email = e.target.value
        
        data.email = email;
        /*
        if (validator.isEmail(email)) {
            setEmailError('Valid Email :)')
        } else {
            setEmailError('Enter valid Email!')
        }
        */
    }

    const validateName = (e) => {
        let nombre = e.target.value
        data.name = nombre;
    }


    const validateJob = (e) => {
        let job = e.target.value
        data.company = job;
    }


    const validatePhone = (e) => {
        let phone = e.target.value
        data.phone = phone;
    }
  

    function isEmptyObj(data) {
       
        if(data.name && data.email && data.company && data.phone){
           return true;
        }else {
           return false;
        }
    }

    function sendEmail(e) {
        e.preventDefault();
        let dataMail = e.target;
        let sendMail = isEmptyObj(data);

        if(sendMail === true){
            emailjs.sendForm('service_q8j7tqj', 'template_x9b4d0p', dataMail, 'user_2bIl4XyTjiOnKomgP5DX9')
                .then((result) => {
                    
                    window.location.replace("/Message");
                    console.log(result.text);

                }, (error) => {
                    console.log(error.text);
                });

            e.target.reset()

        }else if(sendMail === false){
            alert('¡Revisar datos! Los campos Nombre, Email y Empresa no pueden ir vacios ')
        }
        
        
          
    }
    
  

  return (
    <div className='hero-container'>


      <div className="grid">

            <div className="body">
            
                <div className="title">
                Optimizamos la atención centrada en el paciente.
                </div>

                <div className="body-s"> 
                Mejora la visibilidad de todo el recorrido de la salud por medio del CRM especializado en soluciones.
                </div>



                <div className="logo-style-hero">
                
                </div>
           
            </div>
            

            <div className="form">

            <Form className="bck-from" onSubmit={sendEmail}>


                <h1 className="sendEmail-h1">
                    Escríbenos para mayor información
                </h1>

                <p className="sendEmail-p">Llena todos los campos</p>

                <Form.Group className="padding-from">
                    <Col sm={12}>
                        <Form.Control className="control-from" type="text" placeholder="Nombre Completo" name="name" onChange={(e) => validateName(e)} />
                    </Col>
                </Form.Group>

                <Form.Group className="padding-from">
                    <Col sm={12}>
                    <Form.Control className="control-from" type="email" placeholder="Email" name="email" onChange={(e) => validateEmail(e)} />
                    </Col>
                    
                </Form.Group>

                <Form.Group className="padding-from">
                    <Row className="col-section-style">
                        <Col sm={4} className="col-contact">
                            <Form.Control  as="select" name="lada">
                                <option>Lada</option>
                                <option>+52 </option>
                                <option>+502</option>
                                <option>+503</option>
                                <option>+593</option>
                                <option>+51</option>
                                <option>+57</option>
                            </Form.Control>
                        </Col>
                        <Col sm={8} className="none-col-contact">
                            <Form.Control type="text" placeholder="Teléfono" name="phone"  onChange={(e) => validatePhone(e)}/>
                        </Col>
                    </Row>
                  
                </Form.Group>

                <Form.Group className="padding-from ">
                    <Col sm={12}>
                        <Form.Control className="control-from" type="text" placeholder="Empresa" name="job" onChange={(e) => validateJob(e)}/>
                    </Col>
                </Form.Group>

                <Form.Group className="padding-from">
                    <Col sm={12}>
                        <Form.Control className="control-from" type="text" placeholder="Cargo" name="cargo"/>
                    </Col>
                </Form.Group>

                <Form.Group className="padding-from" >

                    <Col sm={12}>
                        <Form.Control as="select" name="pais" className="control-from">
                            <option>País</option>
                            <option>México</option>
                            <option>El Salvador</option>
                            <option>Guatemala</option>
                            <option>Colombia</option>
                            <option>Chile</option>
                            <option>Perú</option>
                            <option>Otro</option>
                        </Form.Control>
                    </Col>

                </Form.Group>
                {/*
                <Button  className='btn-style' variant="primary" type="submit">
                    Enviar
                </Button>

                */}
                <Col sm={12} className="padding-from-btn">
                  <Button primary type="submit" className='btn-style'>Enviar</Button>
                </Col>
                
            </Form>

            </div>
       
            <div className="logo-bottom">

            </div>

      </div>
      
    </div>

   

  );
}

export default HeroSection;


